@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Lato&display=swap');

body {
  margin: 0;
  font-family: 'Raleway Variable', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Audiowide', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .MuiBox-root {
  background: linear-gradient(to right, #ad5389, #222000) !important;
} */

.content {
  min-height: calc(100vh - 70px);
}
.footer {
  height: 50px;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
}
